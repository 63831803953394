import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Licitatii.css';
import { fetchSearchResults } from '../../services/searchService';
import AuctionList from '../../components/AuctionList/AuctionList';
import Spinner from '../../components/Spinner/Spinner';
import logo from '../../assets/icon.png';

const Licitatii = () => {
  const [filters, setFilters] = useState({
    auctionId: '',
    auctionTitle: '',
    publishedDate: '',
    cpvCode: '',
    authorityName: '',
    authorityCUI: '',
    city: '',
    county: '',
    minEstimatedValue: '',
    maxEstimatedValue: '',
    currency: '',
    pageIndex: 0,
    pageSize: 10,
  });

  const [errors, setErrors] = useState([]);
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const validateValues = () => {
    const validationErrors = [];
    if (parseFloat(filters.minEstimatedValue) < 0) {
      validationErrors.push('Valoarea estimată minima nu poate fi negativă.');
    }
    if (parseFloat(filters.maxEstimatedValue) < 0) {
      validationErrors.push('Valoarea estimată maxima nu poate fi negativă.');
    }
    if (parseFloat(filters.maxEstimatedValue) < parseFloat(filters.minEstimatedValue)) {
      validationErrors.push('Valoarea estimată minima nu poate fi mai mare decât valoarea maximă.');
    }
    setErrors(validationErrors);
    return validationErrors.length === 0;
  };

  const handleFilter = async (pageIndex = 0) => {
    if (validateValues()) {
      setLoading(true);
      try {
        const searchFilters = { ...filters, pageIndex };
        const data = await fetchSearchResults(searchFilters);
        setResults(data.results);
        setTotalResults(data.totalResults);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFilters({
      auctionId: '',
      auctionTitle: '',
      publishedDate: '',
      cpvCode: '',
      authorityName: '',
      authorityCUI: '',
      city: '',
      county: '',
      minEstimatedValue: '',
      maxEstimatedValue: '',
      currency: '',
      pageIndex: 0,
      pageSize: 10,
    });
    setErrors([]);
    setResults([]);
    setTotalResults(0);
  };

  const handlePageChange = (newPageIndex) => {
    setFilters({ ...filters, pageIndex: newPageIndex });
    handleFilter(newPageIndex);
  };

  return (
    <>
    <Helmet>
      <title>Licitații - Go4Projects | Portal Achiziții Publice</title>
      <meta name="description" content="Descoperă cele mai recente licitații publice pentru proiecte de construcții pe Go4Projects. Accesează proceduri de achiziții și oferte din întreaga țară." />
      <meta name="keywords" content="licitații publice, achiziții publice, construcții, Go4Projects, proiecte construcții, oferte licitații" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://go4projects.com/licitatii" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Licitații - Go4Projects | Portal Achiziții Publice" />
      <meta property="og:description" content="Descoperă cele mai recente licitații publice pentru proiecte de construcții pe Go4Projects." />
      <meta property="og:url" content="https://go4projects.com/licitatii" />
      <meta property="og:image" content={logo} />
      <meta property="og:type" content="website" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Licitații - Go4Projects | Portal Achiziții Publice" />
      <meta name="twitter:description" content="Descoperă cele mai recente licitații publice pentru proiecte de construcții pe Go4Projects." />
      <meta name="twitter:image" content={logo} />

      {/* WhatsApp Meta Tag */}
      <meta property="og:image" content={logo} />
    </Helmet>

    <section className="bg-white dark:bg-white-900 py-8 section">
      <div className="max-w-screen-xl mx-auto px-4">
        {errors.length > 0 && (
          <div className="bg-red-500 text-white p-4 rounded-lg mb-4">
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">ID Licitație</label>
            <input
              type="text"
              name="auctionId"
              value={filters.auctionId}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              placeholder="ID Licitație"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">Titlu Procedura</label>
            <input
              type="text"
              name="auctionTitle"
              value={filters.auctionTitle}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              placeholder="Titlu Procedura"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">Data Publicării</label>
            <input
              type="date"
              name="publishedDate"
              value={filters.publishedDate}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">Cod CPV</label>
            <input
              type="text"
              name="cpvCode"
              value={filters.cpvCode}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              placeholder="Cod CPV"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">Autoritate</label>
            <input
              type="text"
              name="authorityName"
              value={filters.authorityName}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              placeholder="Autoritate"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">CUI Autoritate</label>
            <input
              type="text"
              name="authorityCUI"
              value={filters.authorityCUI}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              placeholder="CUI Autoritate"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">Oraș</label>
            <input
              type="text"
              name="city"
              value={filters.city}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              placeholder="Oraș"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">Județ</label>
            <input
              type="text"
              name="county"
              value={filters.county}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              placeholder="Județ"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">Valoare Estimată (minim)</label>
            <input
              type="number"
              name="minEstimatedValue"
              value={filters.minEstimatedValue}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              step="0.01"
              placeholder="Minim"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">Valoare Estimată (maxim)</label>
            <input
              type="number"
              name="maxEstimatedValue"
              value={filters.maxEstimatedValue}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              step="0.01"
              placeholder="Maxim"
            />
          </div>
          <div className="relative">
            <label className="block text-white-700 dark:text-white-200 mb-2">Monedă</label>
            <input
              type="text"
              name="currency"
              value={filters.currency}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 border rounded-lg dark:bg-white-800 dark:border-white-700 dark:text-white-200 focus:outline-none"
              placeholder="Monedă"
            />
          </div>
        </div>

        <div className="flex items-center justify-end space-x-4 mt-6">
          <button
            onClick={() => handleFilter()}
            className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-300"
          >
            Filtrează
          </button>
          <button
            onClick={handleReset}
            className="bg-white-200 text-white-700 px-4 py-2 rounded-lg hover:bg-white-300 focus:outline-none"
          >
            Resetează
          </button>
        </div>

        <div className="mt-8">
          {loading ? (
            <div className="flex justify-center items-center">
              <Spinner />
              <p className="ml-4">Se încarcă rezultatele...</p>
            </div>
          ) : results.length > 0 ? (
            <AuctionList
              auctions={results}
              totalResults={totalResults}
              pageSize={filters.pageSize}
              pageIndex={filters.pageIndex}
              onPageChange={handlePageChange}
            />
          ) : (
            <p className="text-center text-white-500">Nu au fost găsite rezultate.</p>
          )}
        </div>
      </div>
    </section>
    </>
  );
};

export default Licitatii;
