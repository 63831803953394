import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL + "/api/auctions/search";

export const fetchSearchResults = async (filters) => {
  console.log('Filters sent to API:', filters);

  // Înlocuim valorile goale ("") cu `null` pentru compatibilitate cu C#
  const cleanedFilters = {
    ...filters,
    publishedDate: filters.publishedDate || null,
    minEstimatedValue: filters.minEstimatedValue ? parseFloat(filters.minEstimatedValue) : null,
    maxEstimatedValue: filters.maxEstimatedValue ? parseFloat(filters.maxEstimatedValue) : null,
    auctionId: filters.auctionId || null,
    auctionTitle: filters.auctionTitle || null,
    cpvCode: filters.cpvCode || null,
    authorityName: filters.authorityName || null,
    authorityCUI: filters.authorityCUI || null,
    city: filters.city || null,
    county: filters.county || null,
    currency: filters.currency || null,
  };

  try {
    const response = await axios.post(API_URL, cleanedFilters, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error.response ? error.response.data : error.message);
    throw error;
  }
};
