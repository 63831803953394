import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import logo from '../../assets/icon.png';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Acasă - Go4Projects | Portal Achiziții Publice Construcții</title>
        <meta name="description" content="Go4Projects este primul portal dedicat achizițiilor publice în construcții, oferind transparență și acces ușor la proiecte." />
        <meta name="keywords" content="achiziții publice, construcții, licitații, Go4Projects, proiecte construcții, portal achiziții" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://go4projects.com/" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Acasă - Go4Projects | Portal Achiziții Publice Construcții" />
        <meta property="og:description" content="Go4Projects este primul portal dedicat achizițiilor publice în construcții, oferind transparență și acces ușor la proiecte." />
        <meta property="og:url" content="https://go4projects.com/" />
        <meta property="og:image" content={logo} />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Acasă - Go4Projects | Portal Achiziții Publice Construcții" />
        <meta name="twitter:description" content="Go4Projects este primul portal dedicat achizițiilor publice în construcții, oferind transparență și acces ușor la proiecte." />
        <meta name="twitter:image" content={logo} />

        {/* WhatsApp Meta Tag */}
        <meta property="og:image" content={logo} />
      </Helmet>


      {/* Secțiunea 1: Licitații zilnice actualizate (Alb) */}
      <section className="section bg-white py-8">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold mb-4">Licitații zilnice actualizate</h2>

        {/* Container pentru scroll orizontal pe mobil */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Cod
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Titlu licitație
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Dată limită depunere
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600">
                  Autoritate contractantă
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-50">
                <td className="px-4 py-4 border-b border-gray-200 text-sm">12345</td>
                <td className="px-4 py-4 border-b border-gray-200 text-sm whitespace-normal">
                  Construcție pod rutier cu lungimea de 100 metri, inclusiv lucrări de infrastructură
                </td>
                <td className="px-4 py-4 border-b border-gray-200 text-sm">25.10.2024</td>
                <td className="px-4 py-4 border-b border-gray-200 text-sm">Primăria București</td>
              </tr>
              <tr className="bg-white">
                <td className="px-4 py-4 border-b border-gray-200 text-sm">67890</td>
                <td className="px-4 py-4 border-b border-gray-200 text-sm whitespace-normal">
                  Reabilitare drum județean cu lungimea de 50 kilometri, inclusiv lucrări de siguranță rutieră
                </td>
                <td className="px-4 py-4 border-b border-gray-200 text-sm">30.10.2024</td>
                <td className="px-4 py-4 border-b border-gray-200 text-sm">CNAIR</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>



      {/* Secțiunea 2: Despre go4projects (Gri) */}
      <section className="section gray-bg">
        <div className="container">
          <h2 className="section-title">Despre go4projects</h2>
          <p className="section-text">
            Primul portal de achiziții publice dedicat proiectelor de construcții go4projects grupează procedurile de achiziții publice în proiecte, asigurând transparență și accesibilitate celor implicați, începând cu planificarea achizițiilor publice, publicarea în SEAP, preluarea și integrarea datelor în go4projects.
          </p>
        </div>
      </section>

{/* Secțiunea 3: Butoane (Alb) */}
<section className="bg-gray-50 dark:bg-gray-800">
  <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
    <div className="max-w-screen-sm mx-auto text-center">
      <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
        Explorați platforma noastră
      </h2>
      <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
        Accesați diferite secțiuni pentru a vedea mai multe detalii despre procedurile noastre.
      </p>
      <div className="button-group">
        <a href="#" className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">
          Proceduri de Achiziție
        </a>
        <a href="#" className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">
          Proiecte
        </a>
        <a href="#" className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">
          Rapoarte
        </a>
      </div>
    </div>
  </div>
</section>


      {/* Secțiunea 4: Cunoașterea aprofundată (Gri) */}
      <section className="section gray-bg">
        <div className="container">
          <h2 className="section-title">Cunoașterea aprofundată a pieței publice de construcții din România</h2>
          <p className="section-text">
            Vă prezentăm noul nostru portal go4projects, conceput pentru a valorifica cunoștințele noastre aprofundate de piața publică a construcțiilor din România. Acest portal vă împuternicește să luați rapid decizii informate, deosebindu-ne ca lider în domeniu.
          </p>
          <p className="section-text">
            Experimentați astăzi avantajele datelor de piață bazate pe experți, accesibile și fiabile prin portalul nostru de ultima oră go4projects!
          </p>
        </div>
      </section>

    </>
  );
};

export default Home;
