import React from 'react';
import './AuctionItem.css';
import { format } from 'date-fns';
import Utility from './../../utils/Utility'; 

const AuctionItem = ({ auction }) => {
  // Formatăm data pentru a fi mai elegantă (ex: 12 Aug 2024)
  const formattedDate = format(new Date(auction.publishedDate), 'dd MMM yyyy');

  return (
    <div className="auction-item">
      <div className="auction-id-section">
        <h2 className="auction-id">{auction.auctionId}</h2>
      </div>

      <div className="auction-details">
        <h3 className="auction-title">{auction.auctionTitle}</h3>
        <p className="auction-authority">
          {auction.city !== 'Undefined' ? auction.city : 'Locație necunoscută'}, {auction.authorityName}
        </p>
      </div>

      <div className="auction-footer">
        <p className="auction-value">Valoare estimată: <span>{Utility.ConvertEstimatedValue(auction.estimatedValue)} {auction.currency}</span></p>
        <p className="auction-date">Publicată la: {formattedDate}</p>
      </div>
    </div>
  );
};

export default AuctionItem;
