import React from 'react';
import Header from './components/Header/Header';
import Licitatii from './pages/Licitatii/Licitatii';
import Footer from './components/Footer/Footer';
import NotFoundComponent from './components/NotFoundComponent';
import CookiesPolicy from "./components/CookiesPolicy";
import Home from "./pages/Home/Home";
import Subscriptions from './pages/Subscriptions/Subscriptions';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <br /><br />
        <main className="container container-fluid">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/licitatii/" element={<Licitatii />} />
            <Route path="/abonamente/" element={<Subscriptions />} />
            <Route path="/404" element={<NotFoundComponent />} />
            <Route path="**" element={<NotFoundComponent />} />
          </Routes>
          <div className='p-4'>
            <CookiesPolicy />
          </div>
        </main>
      </Router>
      <Footer />
    </>
  );
};

export default App;
