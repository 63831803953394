// src/utils/Utility.js

class Utility {
    static ConvertEstimatedValue(value) {
      return value.toLocaleString('de-DE', {
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2
      });
    }
  }
  
  export default Utility;
  