import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/logo.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('/');
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (path) => {
    setActiveLink(path);
    setIsMenuOpen(false);
  };

  React.useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    <header className="fixed w-full top-0 z-50">
      <nav className="bg-white border-gray-200 py-2.5 dark:bg-gray-900">
        <div className="flex justify-between items-center max-w-screen-xl px-4 mx-auto">
          <Link to="/" className="flex items-center" onClick={() => handleLinkClick('/')}>
            <img src={logo} className="h-10 mr-4 sm:h-12" alt="Logo" />
            {/* <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              Go4Projects
            </span> */}
          </Link>

          <div className="flex items-center lg:order-2">
            <Link
              to="/login"
              className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
              onClick={() => handleLinkClick('/login')}
            >
              Login
            </Link>
            <a
              href="#"
              className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
            >
              Call an Expert
            </a>
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded={isMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              {!isMenuOpen ? (
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
          </div>

          <div className="hidden lg:flex lg:items-center lg:space-x-8">
            <Link
              to="/"
              className={`block py-2 pl-3 pr-4 ${
                activeLink === '/'
                  ? 'text-white bg-purple-700'
                  : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
              } rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white`}
              onClick={() => handleLinkClick('/')}
            >
              Acasa
            </Link>
            <Link
              to="/licitatii"
              className={`block py-2 pl-3 pr-4 ${
                activeLink === '/licitatii'
                  ? 'text-white bg-purple-700'
                  : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
              } rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white`}
              onClick={() => handleLinkClick('/licitatii')}
            >
              Licitații
            </Link>
            <Link
              to="/proiecte"
              className={`block py-2 pl-3 pr-4 ${
                activeLink === '/proiecte'
                  ? 'text-white bg-purple-700'
                  : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
              } rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white`}
              onClick={() => handleLinkClick('/proiecte')}
            >
              Proiecte
            </Link>
            <Link
              to="/abonamente"
              className={`block py-2 pl-3 pr-4 ${
                activeLink === '/abonamente'
                  ? 'text-white bg-purple-700'
                  : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
              } rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white`}
              onClick={() => handleLinkClick('/abonamente')}
            >
              Abonamente
            </Link>
            <Link
              to="/blog"
              className={`block py-2 pl-3 pr-4 ${
                activeLink === '/blog'
                  ? 'text-white bg-purple-700'
                  : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
              } rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white`}
              onClick={() => handleLinkClick('/blog')}
            >
              Blog
            </Link>
            <Link
              to="/contact"
              className={`block py-2 pl-3 pr-4 ${
                activeLink === '/contact'
                  ? 'text-white bg-purple-700'
                  : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
              } rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white`}
              onClick={() => handleLinkClick('/contact')}
            >
              Contact
            </Link>
          </div>
        </div>

        <div
          className={`${
            isMenuOpen ? 'block' : 'hidden'
          } lg:hidden w-full`}
          id="mobile-menu-2"
        >
          <ul className="flex flex-col mt-4 space-y-2 font-medium">
            <li>
              <Link
                to="/"
                className={`block py-2 pl-3 pr-4 ${
                  activeLink === '/'
                    ? 'text-white bg-purple-700'
                    : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
                } rounded dark:text-white`}
                onClick={() => handleLinkClick('/')}
              >
                Acasa
              </Link>
            </li>
            <li>
              <Link
                to="/licitatii"
                className={`block py-2 pl-3 pr-4 ${
                  activeLink === '/licitatii'
                    ? 'text-white bg-purple-700'
                    : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
                } rounded dark:text-white`}
                onClick={() => handleLinkClick('/licitatii')}
              >
                Licitații
              </Link>
            </li>
            <li>
              <Link
                to="/proiecte"
                className={`block py-2 pl-3 pr-4 ${
                  activeLink === '/proiecte'
                    ? 'text-white bg-purple-700'
                    : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
                } rounded dark:text-white`}
                onClick={() => handleLinkClick('/proiecte')}
              >
                Proiecte
              </Link>
            </li>
            <li>
              <Link
                to="/abonamente"
                className={`block py-2 pl-3 pr-4 ${
                  activeLink === '/abonamente'
                    ? 'text-white bg-purple-700'
                    : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
                } rounded dark:text-white`}
                onClick={() => handleLinkClick('/abonamente')}
              >
                Abonamente
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                className={`block py-2 pl-3 pr-4 ${
                  activeLink === '/blog'
                    ? 'text-white bg-purple-700'
                    : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
                } rounded dark:text-white`}
                onClick={() => handleLinkClick('/blog')}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={`block py-2 pl-3 pr-4 ${
                  activeLink === '/contact'
                    ? 'text-white bg-purple-700'
                    : 'text-gray-700 border-b border-gray-100 hover:bg-gray-50'
                } rounded dark:text-white`}
                onClick={() => handleLinkClick('/contact')}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
